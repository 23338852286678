import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SeasonYear from '../../components/SeasonYear';
import pic11 from '../../assets/images/publication-banners/pioneer-theatre-company.jpg';

export const query = graphql`
  query PioneerQuery {
    allPtcCsv {
      nodes {
        CD
        FR
        Quantity
        RUNNING_DATES
        SHOW
        Image
      }
    }
  }
`;

export default function PioneerTheatre({ data }) {
  const pioneerNodes = data.allPtcCsv.nodes;
  // console.log(pioneerNodes);

  const imageRef = '/season-images/pioneer/';
  // append the image reference with the path to the image via the static folder

  return (
    <Layout>
      <Helmet>
        <title>Pioneer Theatre Company - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Advertising and event information for Pioneer Theatre Company’s season playbills. Please contact Mills Publishing for availability and rates."
        />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Pioneer Theatre Company</h1>
            </header>
            <span className="image main">
              <img src={pic11} alt="" />
            </span>
            <p className="box">
              As one of Utah’s oldest theatres, Pioneer Theatre Company is known
              for its combination of classics and premiere shows. Since it’s
              located on the University of Utah’s campus, their audience has
              their fair share of great thinkers, including faculty, healthcare
              workers, researchers, and more.
            </p>
            <ul className="actions">
              <li>
                <a
                  href="https://www.pioneertheatre.org/"
                  className="button special"
                >
                  <i className="icon fa-globe"> </i>Pioneer Theatre Company
                  Website
                </a>
              </li>
            </ul>
            <h2>
              Pioneer Theatre Company <SeasonYear /> Season Information
            </h2>
            <p>
              <em>
                Dates are subject to change. Please contact us if you have any
                questions.
              </em>
            </p>
            <div className="grid-wrapper">
              {/* Calls the season json, accesses the ecclesSeason array, and maps/creates a new array */}
              {pioneerNodes.map((node) => {
                return (
                  <div className="col-4 box" key={node.id}>
                    <img
                      style={{ width: 100 + '%' }}
                      src={imageRef + node.Image}
                    />
                    <h3>{node.SHOW}</h3>
                    <strong>{node.RUNNING_DATES}</strong> <br />
                    <br />
                    <h5>Advertising Information</h5>
                    <section className="box">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              File Ready Deadline: <br />
                              {node.FR}
                            </td>
                          </tr>
                          <tr>
                            <td>Print Quantity: {node.Quantity}</td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
